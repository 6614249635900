import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Dashboard.css";
import createTeamConfig from "../../config/createTeamConfig.json";

const Dashboard = ({ generalCyclists, generalRankings, espoirRankings, races, today, showSession }) => {
    const navigate = useNavigate();

    //const today = new Date();
    today.setHours(0, 0, 0, 0);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };

    const closeDate = new Date(createTeamConfig.closeDate);
    const changeableDates = Object.entries(createTeamConfig)
        .filter(([key]) => key.startsWith("changeableDate"))
        .map(([, value]) => new Date(value))
        .sort((a, b) => a - b);

    const normalizedToday = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));

    const nextChangeableDate = changeableDates.find(date => {
        const normalizedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return normalizedDate >= normalizedToday;
    });


    let transferStatus = "";
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const todayStr = today.toLocaleDateString('fr-CA', options);
    const closeDateStr = closeDate.toLocaleDateString('fr-CA', options);

    if (todayStr >= closeDateStr) {
        if (generalCyclists.length > 0) {
            if (nextChangeableDate) {
                
                if (nextChangeableDate.toISOString().split("T")[0] === normalizedToday.toISOString().split("T")[0]) {
                    transferStatus = "Accéder au transfert";
                } else {
                    transferStatus = `Prochaine date de transfert : ${formatDate(nextChangeableDate)}`;
                }
            } else {
                transferStatus = "Aucune prochaine date de transfert";
            }
        } else {
            transferStatus = "Faire mon équipe";
        }
    } else {
        transferStatus = "Faire mon équipe";
    }

    const lastRaces = races
        .filter(race => {
            const endDate = new Date(race.date_end);
            return endDate < today;
        })
        .sort((a, b) => new Date(b.date_end) - new Date(a.date_end))
        .slice(0, 5);

    const nextRaces = races
        .filter(race => {
            const startDate = new Date(race.date_start);
            return startDate >= today;
        })
        .sort((a, b) => new Date(a.date_start) - new Date(b.date_start))
        .slice(0, 5);

    const handleRowClick = (raceId) => {
        navigate(`/show-race-detail/${raceId}`);
    };

    return (
        <div className="dashboard">
            {/* Top Section */}
            <div className="dashboard-top">
                {/* Left Section */}
                <div className="dashboard-top-left">
                    <div className="dashboard-header">
                        <div className="dashboard-header-title">
                            <h2>
                                Mon équipe
                                
                            </h2>
                            <span onClick={() => showSession("MyTeam")}>
                                +
                            </span>
                        </div>
                        
                        {today.getDate() === 1 && (
                            <strong onClick={() => navigate("/changeBonusCyclist")}>Modifier le coureur bonus</strong>
                        )}
                        <a href="https://www.velofute.com/programme-des-leaders-en-cyclisme-2025" target="_blank">Programme des leaders en cyclisme 2025</a>
                        <p
                            onClick={() => {
                                if (transferStatus === "Faire mon équipe") {
                                    navigate("/createTeam");
                                } else if (transferStatus === "Accéder au transfert") {
                                    navigate("/changeTeam");
                                }
                            }}
                        >
                            <strong>{transferStatus}</strong>
                        </p>
                    </div>
                    <div className="dashboard-top-left-content">
                        <div className="dashboard-cyclist-grid">
                            {generalCyclists.slice(0, 8).map((cyclist, index) => (
                                <div key={index} className="dashboard-cyclist-cell">
                                    <div className="dashboard-cyclist-info">
                                        <div className="dashboard-cyclist-name">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality
                                                    .normalize("NFD")
                                                    .replace(/[\u0300-\u036f]/g, '')
                                                    .replace(/ /g, '_')
                                                    .toLowerCase()}.png`} 
                                                alt={`Drapeau de ${cyclist.nationality}`} 
                                                width="20" 
                                                height="20"
                                                className="dashboard-cyclist-flag"
                                            />
                                            <h4>{cyclist.name}</h4>
                                        </div>
                                        <p>{cyclist.team}</p>
                                        <p>Valeur: <span>{cyclist.finalValue}</span></p>
                                    </div>
                                    <div
                                        className="dashboard-cyclist-color-bar"
                                        style={{ backgroundColor: cyclist.teamColor }}
                                    ></div>
                                </div>
                            ))}
                        </div>
                        <div className="dashboard-least-profitable">
                            <table className="dashboard-least-profitable-table">
                                <thead>
                                    <tr>
                                        <th>Les moins rentables (points)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {generalCyclists
                                        .slice()
                                        .sort((a, b) => a.cyclistPoints - b.cyclistPoints)
                                        .slice(0, 5)
                                        .map((cyclist, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="dashboard-cyclist-row">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality
                                                                .normalize("NFD")
                                                                .replace(/[\u0300-\u036f]/g, '')
                                                                .replace(/ /g, '_')
                                                                .toLowerCase()}.png`} 
                                                            alt={`Drapeau de ${cyclist.nationality}`} 
                                                            width="20" 
                                                            height="20"
                                                        />
                                                        <span>{cyclist.name}</span>
                                                    </div>
                                                </td>
                                                <td>{cyclist.cyclistPoints}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* Right Section */}
                <div className="dashboard-top-right">
                    <div className="dashboard-header">
                        <h2>Classement Général</h2>
                    </div>
                    <div className="dashboard-top-right-content">
                        <table className="dashboard-ranking-table">
                            <thead>
                                <tr>
                                    <th>Position</th>
                                    <th>Nom du Joueur</th>
                                    <th>Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {generalRankings.slice(0, 6).map((ranking, index) => (
                                    <tr
                                        key={index}
                                        className={ranking.userId === generalRankings.userId ? "dashboard-player-row" : ""}
                                    >
                                        <td>{ranking.position}</td>
                                        <td>{ranking.name}</td>
                                        <td>{ranking.total_points}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Bottom Section */}
            <div className="dashboard-bottom">
                {/* First Container */}
                <div className="dashboard-bottom-container">
                    <div className="dashboard-header">
                        <h2>Mes dernières courses</h2>
                    </div>
                    <div className="dashboard-last-races-content">
                        <ul className="dashboard-last-races-list">
                            {lastRaces.slice(0, 5).map((race, index) => (
                                <li key={index} className="dashboard-last-race-item" onClick={() => handleRowClick(race.raceId)}>
                                    <div className="dashboard-last-race-flag">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/flags/${race.country
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, '')
                                            .replace(/ /g, '_')
                                            .toLowerCase()}.png`} 
                                        alt={`Drapeau de ${race.country}`} 
                                        width="20" 
                                        height="20"
                                    />
                                    </div>
                                    <span className="dashboard-last-race-name">{race.name}</span>
                                    <span className="dashboard-last-race-dates">{race.racePoints}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Second Container */}
                <div className="dashboard-bottom-container">
                    <div className="dashboard-header">
                        <h2>Mes prochaines courses</h2>
                    </div>
                    <div className="dashboard-next-races-content">
                        <table className="dashboard-next-races-table">
                            <thead>
                                <tr>
                                    <th>Départ</th>
                                    <th>Nom de la course</th>
                                    <th>Arrivée</th>
                                </tr>
                            </thead>
                            <tbody>
                                {nextRaces.map((race, index) => (
                                    <tr key={index}>
                                        <td>{formatDate(race.date_start)}</td>
                                        <td className="dashboard-race-name">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/flags/${race.country
                                                    .normalize("NFD")
                                                    .replace(/[\u0300-\u036f]/g, '')
                                                    .replace(/ /g, '_')
                                                    .toLowerCase()}.png`} 
                                                alt={`Drapeau de ${race.country}`} 
                                                width="20" 
                                                height="20"
                                            />
                                            {race.name}
                                        </td>
                                        <td>{formatDate(race.date_end)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Third Container */}
                <div className="dashboard-bottom-container">
                    <div className="dashboard-header">
                        <h2>Classement Espoir</h2>
                    </div>
                    <div className="dashboard-top-right-content">
                        <table className="dashboard-ranking-table">
                            <thead>
                                <tr>
                                    <th>Position</th>
                                    <th>Nom du Joueur</th>
                                    <th>Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {espoirRankings.slice(0, 7).map((ranking, index) => (
                                    <tr
                                        key={index}
                                        className={ranking.userId === espoirRankings.userId ? "dashboard-player-row" : ""}
                                    >
                                        <td>{ranking.position}</td>
                                        <td>{ranking.name}</td>
                                        <td>{ranking.total_points}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
