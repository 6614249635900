import React, { useState, useEffect } from "react";
import { useNavigate, useLocation  } from "react-router-dom";
import { useAuth } from "../AuthContext";

import Dashboard from "./ProfilView/Dashboard";
import MyTeam from "./ProfilView/MyTeam";
import Ranking from "./ProfilView/Ranking";
import MyRaces from "./ProfilView/MyRaces";
import MyProfile from "./ProfilView/MyProfile";

import "./styles/Profil.css";

import cible from "../medias/png/icons/cible.png";

import baroudeur from "../medias/png/background/baroudeurs.png";
import courseaetape from "../medias/png/background/courses.png";
import classicman from "../medias/png/background/classicman.png";
import polyvalent from "../medias/png/background/polyvalents.png";
import sprinteur from "../medias/png/background/sprinteurs.png";
import chrono from "../medias/png/background/chrono.png";

const Profil = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, userId } = useAuth();
    const [generalCyclists, setGeneralCyclists] = useState([]);
    const [espoirCyclists, setEspoirCyclists] = useState([]);
    const [generalRankings, setGeneralRankings] = useState([]);
    const [generalRankings2024, setGeneralRankings2024] = useState([]);
    const [espoirRankings, setEspoirRankings] = useState([]);
    const [races, setRaces] = useState([]);
    const [emblems, setEmblems] = useState([]);

    const [epopeeLogo, setEpopeeLogo] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL;
    const queryParams = new URLSearchParams(location.search);
    const initialSection = queryParams.get('section');
    const [sectionActive, setSectionActive] = useState(initialSection || "Dashboard");

    const [selectedDate, setSelectedDate] = useState(new Date());

    const [confirmValidDataPopup, setConfirmValidDataPopup] = useState(false);

    /*useEffect(() => {
        if (window.adsbygoogle) {
            window.adsbygoogle.push({});
        }
    }, []);*/

    const handleDateChange = (event) => {
        const newDate = new Date(event.target.value);
        //setSelectedDate(newDate);
    };

    const showSession = (sectionName) => {
        setSectionActive(sectionName);
    };

    const normalizeSpeciality = (speciality) =>
        speciality
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s+/g, "")
            .toLowerCase(); 
    
    useEffect(() => {
        const fetchCyclists = async () => {
            if (!user) {
                navigate("/logIn");
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/cyclistsListUser`, {
                    credentials: "include",
                });

                if (!response.ok) {
                    setGeneralCyclists([]);
                    setEspoirCyclists([]);
                    throw new Error("Erreur lors de la récupération des cyclistes");
                }

                const data = await response.json();

                setGeneralCyclists(data.generalCyclists || []);
                setEspoirCyclists(data.espoirCyclists || []);

                const specialityImages = {
                    baroudeur: baroudeur,
                    courseaetapes: courseaetape,
                    classicman: classicman,
                    polyvalent: polyvalent,
                    sprinteur: sprinteur,
                    contrelamontre: chrono,
                };

                const normalizedSpecialityImages = Object.keys(specialityImages).reduce((acc, key) => {
                    acc[key] = specialityImages[key];
                    return acc;
                }, {});

                const specialityCounts = (data.generalCyclists || []).reduce((acc, cyclist) => {
                    const normalized = normalizeSpeciality(cyclist.speciality);
                    acc[normalized] = (acc[normalized] || 0) + 1;
                    return acc;
                }, {});

                const mostFrequentSpeciality = Object.keys(specialityCounts).reduce((a, b) =>
                    specialityCounts[a] >= specialityCounts[b] ? a : b
                );

                console.log("Normalized Most Frequent Speciality:", mostFrequentSpeciality);
                setEpopeeLogo(normalizedSpecialityImages[mostFrequentSpeciality]);
            } catch (error) {
                console.error("Erreur:", error);
            }
        };

        fetchCyclists();
    }, [apiUrl, user, navigate, setGeneralCyclists, setEspoirCyclists]);   

    useEffect(() => {
        const fetchRankings = async () => {
            if (!user) {
                navigate("/logIn");
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/ranking`, {
                    credentials: "include",
                });
    
                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération du classement");
                }
    
                const data = await response.json();
    
                setGeneralRankings(data.generalRankings);
                setGeneralRankings2024(data.generalRankings2024);
                setEspoirRankings(data.espoirRankings);
    
            } catch (error) {
                console.error("Erreur:", error);
            }
        };
    
        fetchRankings();
    }, [apiUrl, user, navigate]);

    useEffect(() => {
        const fetchEmblems = async () => {
            if (!user) {
                navigate("/logIn");
                return;
            }
    
            try {
                const response = await fetch(`${apiUrl}/getUserEmblems`, {
                    credentials: "include",
                });
    
                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des emblèmes");
                }
    
                const data = await response.json();
    
                setEmblems(data.emblems);
    
            } catch (error) {
                console.error("Erreur:", error);
            }
        };
    
        fetchEmblems();
    }, [apiUrl, user, navigate]);
    

    useEffect(() => {   
        if (!user) {
            navigate("/logIn");
            return;
        }

        const fetchPersonalRaces = async () => {
            try {
                const response = await fetch(`${apiUrl}/racesListUser`, {
                    credentials: "include"
                });
    
                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des courses personnelles");
                }
    
                const data = await response.json();
                setRaces(data);
            } catch (error) {
                console.error("Erreur:", error);
            }
        };
    
        fetchPersonalRaces();

    }, [apiUrl, user, navigate]);

    const handleClosePopup = () => {
        setConfirmValidDataPopup(false);
    };

    return (
        <main className="profil">
            {
                confirmValidDataPopup && (
                    <div className="select-races-bonus-popup">
                        <div className="select-races-bonus-popup-content">
                            <p>Le classement a été réinitialisé et sera bientôt remis à jour</p>
                            <button onClick={handleClosePopup}>Fermer</button>
                        </div>
                    </div>
                )
            }
            <div className="profil-menu">
                <div className="profil-menu-profil">
                    {epopeeLogo && <img src={epopeeLogo} alt="Logo de l'épopée" />}
                    <p>
                        Class. actuel :{" "}
                        {(() => {
                            const userRanking = generalRankings.find(ranking => ranking.userId === userId);
                            return userRanking ? `${userRanking.position}e` : "Non classé";
                        })()}
                    </p>
                    <p>Classement 2024 : {generalRankings2024}</p>
                    <p>Nombre d'emblèmes : {emblems.length}</p>
                    <input
                        id="date-select"
                        type="date"
                        value={new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000)
                            .toISOString()
                            .split("T")[0]}
                        onChange={handleDateChange}
                    />
                </div>
                <div className="profil-menu-right">
                    <div className="profil-menu-onglet">
                        <h4
                            onClick={() => showSession("Dashboard")}
                            className={sectionActive === "Dashboard" ? "active" : ""}
                        >
                            TABLEAU DE BORD
                        </h4>
                        <h4
                            onClick={() => showSession("MyTeam")}
                            className={sectionActive === "MyTeam" ? "active" : ""}
                        >
                            MON ÉQUIPE
                        </h4>
                        <h4
                            onClick={() => showSession("Ranking")}
                            className={sectionActive === "Ranking" ? "active" : ""}
                        >
                            CLASSEMENT
                        </h4>
                        <h4
                            onClick={() => showSession("MyRaces")}
                            className={sectionActive === "MyRaces" ? "active" : ""}
                        >
                            MON CALENDRIER
                        </h4>
                        <h4
                            onClick={() => showSession("MyProfile")}
                            className={sectionActive === "MyProfile" ? "active" : ""}
                        >
                            PROFIL
                        </h4>
                    </div>

                    <div className="profil-infos">
                        <div className="profil-image-container">
                            <img src={cible} alt="Target Icon" className="profil-image" />
                        </div>

                        {[0, 1, 2].map(groupId => {
                            const filteredRaces = races.filter(race => {
                                const raceDate = new Date(race.date_start);
                                raceDate.setHours(0, 0, 0, 0);
                                
                                const normalizedSelectedDate = new Date(selectedDate);
                                normalizedSelectedDate.setHours(0, 0, 0, 0);
                            
                                return (
                                    race.groupCompetitionId === groupId &&
                                    race.isBonus === 1 &&
                                    raceDate > normalizedSelectedDate
                                );
                            });
                            
                            if (filteredRaces.length === 0) return null;
                            
                            if (filteredRaces.length === 0) return null;

                            const groupTitle = groupId === 0 ? "World Tour" : groupId === 1 ? "Continental" : "Pro Series";

                            return (
                                <div className="profil-container" key={groupId}>
                                    {filteredRaces.map((race, index) => (
                                        <div className="profil-row" key={index}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/flags/${race.country
                                                    .normalize("NFD")
                                                    .replace(/[\u0300-\u036f]/g, '')
                                                    .replace(/ /g, '_')
                                                    .toLowerCase()}.png`} 
                                                alt={`Drapeau de ${race.country}`} 
                                                width="20" 
                                                height="20"
                                            />
                                            <span>
                                                {race.name}
                                            </span>
                                        </div>
                                    ))}
                                    <div className="profil-row">
                                        <span className="profil-badge">{groupTitle}</span>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="profil-pub">
                            <ins
                                className="adsbygoogle"
                                style={{ display: "inline-block", width: "400px", height: "100px" }}
                                data-ad-client="ca-pub-1359392757710017"
                                data-ad-slot="1175616414"
                            ></ins>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profil-content-container">
                {sectionActive === "Dashboard" && (
                    <Dashboard
                        generalCyclists={generalCyclists}
                        espoirCyclists={espoirCyclists}
                        generalRankings={generalRankings}
                        espoirRankings={espoirRankings}
                        races={races}
                        today={selectedDate}
                        showSession={showSession}
                    />
                )}
                {sectionActive === "MyTeam" && <MyTeam generalCyclists={generalCyclists} espoirCyclists={espoirCyclists} />}
                {sectionActive === "Ranking" && <Ranking generalRankings={generalRankings} espoirRankings={espoirRankings} />}
                {sectionActive === "MyRaces" && <MyRaces races={races} today={selectedDate} />}                
                {sectionActive === "MyProfile" && <MyProfile emblems={emblems} generalCyclists={generalCyclists} />} 
            </div>
        </main>
        
    );
}

export default Profil;

